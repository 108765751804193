<div class="background">
  <div class="not-found-container">
    <div class="not-found-content">
      <h1>404</h1>
      <p>We're working on it!</p>
    </div>
    <div class="illustration">
      <img src="assets/images/not-found.svg" alt="404 not found" />
    </div>
  </div>

</div>
