import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorage } from 'projects/freelancer-portal/src/app/modules/shared/services/storage/local.storage.service';
import { userProfileInterface } from '../interfaces/user-profile.interface';
import { MultiWarehouseListInterface } from '../modules/private/multi-warehouse/interfaces/multiwarehouse.interface';
import { STORAGE } from '../modules/shared/helpers/enums';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private userProfile: userProfileInterface | undefined;

  constructor(private readonly localStorage: LocalStorage) {
    this.getUserData();
  }

  saveUserData(userData: any): void {
    this.localStorage.save(STORAGE.TOKEN, userData.token);
    userData.roles = userData.type
      ? userData.type.split('^').filter((role: string) => role && role !== ',')
      : null;
    this.localStorage.save(STORAGE.USER, userData);
    this.userProfile = this.manageUserObject(userData);
    if (userData?.warehouses.length === 1) {
      this.setSelectedWarehouseInfoInUserAndLocalService(
        this.getUserData()?.warehouses
      );
    }
  }

  getUserData(): userProfileInterface {
    return (
      this.userProfile ||
      (this.userProfile = this.manageUserObject(
        this.localStorage.get<any>(STORAGE.USER)
      ))
    );
  }

  private manageUserObject(userData: any): userProfileInterface {
    const formatedObject: any = {};
    if (userData) {
      Object.keys(userData).forEach((keys: any) => {
        formatedObject[_.camelCase(keys)] = userData[keys] || '';
      });
      if (userData.warehouses.length === 1) {
        this.setWarehouse(userData.warehouses);
      }
    }

    return formatedObject;
  }

  public resetUserData() {
    this.userProfile = undefined;
  }

  setWarehouse(obj: any) {
    if (this.userProfile) {
      this.userProfile.warehouseId = obj.id;
      this.userProfile.warehouseName = obj.name;
      this.userProfile = { ...this.userProfile, ...this.userProfile };
      this.localStorage.save(STORAGE.USER, this.userProfile);
    }
  }

  getWarehouseFromLocalStorage() {
    const userProfile: userProfileInterface = this.localStorage.get(
      STORAGE.USER
    );
    if (userProfile && userProfile?.warehouseId) {
      const warehouse = {
        id: userProfile.warehouseId,
        name: userProfile.warehouseName,
      };
      return warehouse;
    }
    return null;
  }

  public hasToken(): boolean {
    return !!this.getUserData()?.token;
  }

  public setSelectedWarehouseInfoInUserAndLocalService(
    warehouses: MultiWarehouseListInterface[]
  ) {
    if (this.userProfile) {
      {
        this.userProfile.warehouseId = warehouses[0].id;
        this.userProfile.warehouseName = warehouses[0].name;
        this.userProfile.selectedWarehouse = warehouses[0].id;
        this.userProfile = { ...this.userProfile, ...this.userProfile };
        this.localStorage.save(STORAGE.USER, this.userProfile);
      }
    }
  }

  public updateWarehouseList(warehouseList: MultiWarehouseListInterface[]) {
    // Update the user profile's warehouses
    // Update the user profile object
    if (this.userProfile) {
      this.userProfile.warehouses = warehouseList;
    }

    // Update the local storage with the new warehouse list
    this.localStorage.save(STORAGE.USER, {
      ...this.localStorage.get<any>(STORAGE.USER),
      warehouses: warehouseList,
    });
  }

  //Set a preference with 24Hrs Expiry
  public set24HrsPreference(preferenceName: string) {
    if (!this.userProfile) this.userProfile = this.getUserData();
    const currentTime = new Date().getTime();
    this.userProfile[preferenceName] = currentTime;
    this.userProfile = { ...this.userProfile, ...this.userProfile };
    this.localStorage.save(STORAGE.USER, this.userProfile);
  }

  // Check if preference is expired or not
  public isPreferenceExpired(preferenceName: string): boolean {
    if (this.userProfile && this.userProfile[preferenceName]) {
      const storedTime = this.userProfile[preferenceName];
      const currentTime = new Date().getTime();
      const expiryTime = 24 * 60 * 60 * 1000;
      const isExpired = currentTime - storedTime > expiryTime;
      return isExpired;
    }
    return true;
  }
}
