export interface ProductLegend {
  name: string;
  borderColor: string;
  backgroundColor: string;
}

export enum EquipmentRangeLegends {
  Available = 'Available',
  Repair = 'Repair',
  Quoted = 'Quoted',
  Reserved = 'Reserved',
  Booked = 'Booked',
  Pulled = 'Pulled',
  Missing = 'Missing',
  Warning = 'Warning',
  Shortage = 'Shortage',
  Unavailable = 'Unavailable',
  Onsite = 'Onsite',
}

export enum EquipmentDetailRangeLegends {
  Any = 'Any',
  Available = 'Available',
  Repair = 'Repair',
  Quoted = 'Quoted',
  Reserved = 'Reserved',
  Booked = 'Booked',
  Pulled = 'Pulled',
  Missing = 'Missing',
  Warning = 'Warning',
  Shortage = 'Shortage',
  Unavailable = 'Unavailable',
  Onsite = 'Onsite',
}

export interface ProductLegendStyle {
  backgroundColor: string;
  borderColor: string;
}

export const LegendColors: Record<string, ProductLegendStyle> = {
  quoted: {
    backgroundColor: 'rgba(255, 236, 179, 1)',
    borderColor: 'rgba(255, 186, 8, 1)',
  },
  repair: {
    backgroundColor: 'rgba(255, 204, 204, 1)',
    borderColor: 'rgba(255, 89, 89, 1)',
  },
  available: {
    backgroundColor: 'rgba(204, 255, 204, 1)',
    borderColor: 'rgba(0, 204, 102, 1)',
  },
  warning: {
    backgroundColor: 'rgba(255, 224, 204, 1)',
    borderColor: 'rgba(255, 153, 51, 1)',
  },
  shortage: {
    backgroundColor: 'rgba(255, 255, 204, 1)',
    borderColor: 'rgba(153, 153, 0, 1)',
  },
  pulled: {
    backgroundColor: 'rgba(204, 255, 230, 1)',
    borderColor: 'rgba(0, 153, 102, 1)',
  },
  reserved: {
    backgroundColor: 'rgba(255, 204, 255, 1)',
    borderColor: 'rgba(204, 0, 204, 1)',
  },
  booked: {
    backgroundColor: 'rgba(255, 204, 153, 1)',
    borderColor: 'rgba(255, 153, 0, 1)',
  },
  partitioned: {
    backgroundColor: 'rgba(204, 229, 255, 1)',
    borderColor: 'rgba(0, 102, 204, 1)',
  },
  unavailable: {
    backgroundColor: 'rgba(204, 229, 255, 1)',
    borderColor: 'rgba(0, 102, 204, 1)',
  },
  missing: {
    backgroundColor: 'rgba(255, 221, 221, 1)',
    borderColor: 'rgba(255, 0, 0, 1)',
  },
  any: {
    backgroundColor: 'rgba(245, 245, 245, 1)',
    borderColor: 'rgba(224, 224, 224, 1)',
  },
  lost: {
    backgroundColor: 'rgba(211, 211, 211, 1)',
    borderColor: 'rgba(169, 169, 169, 1)',
  },
  PULLSHEET: {
    backgroundColor: 'rgba(204, 255, 230, 1)',
    borderColor: 'rgba(0, 153, 102, 1)',
  },
  RETURNSHEET: {
    backgroundColor: 'rgba(255, 255, 204, 1)',
    borderColor: 'rgba(153, 153, 0, 1)',
  },
  both: {
    backgroundColor: 'rgba(255, 204, 153, 1)',
    borderColor: 'rgba(255, 153, 0, 1)',
  },
  onsite: {
    backgroundColor: 'rgba(230, 230, 255, 1)',
    borderColor: 'rgba(102, 102, 255, 1)',
  },
};

export const EquipmentLegends: ProductLegend[] = Object.values(
  EquipmentRangeLegends
).map((name) => ({
  name,
  borderColor: LegendColors[name.toLowerCase()]?.borderColor,
  backgroundColor: LegendColors[name.toLowerCase()]?.backgroundColor,
}));

export const EquipmentDetailLegends: ProductLegend[] = Object.values(
  EquipmentDetailRangeLegends
).map((name) => ({
  name,
  borderColor: LegendColors[name.toLowerCase()]?.borderColor,
  backgroundColor: LegendColors[name.toLowerCase()]?.backgroundColor,
}));

export const timePeriodList = [
  { id: 'day', name: 'Day', type: 1, isActive: false },
  { id: 'week', name: 'Week', type: 7, isActive: true },
  { id: 'month', name: 'Month', type: 30, isActive: false },
];

export const AreaBookingLegends = [
  {
    name: 'Pull Sheet',
    backgroundColor: LegendColors['PULLSHEET'].backgroundColor,
    borderColor: LegendColors['PULLSHEET'].borderColor,
  },
  {
    name: 'Return Sheet',
    backgroundColor: LegendColors['RETURNSHEET'].backgroundColor,
    borderColor: LegendColors['RETURNSHEET'].borderColor,
  },
];
