import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { lastValueFrom, Subject, takeUntil, timer } from 'rxjs';
import { OfflinePopupComponent } from '../components/offline-popup/offline-popup.component';
import { ApiPaths } from '../helpers/api-paths';
import { SharedHelper } from '../helpers/shared-helper';
import { GenericService } from './generic.service';

@Injectable({ providedIn: 'root' })
export class PingService implements OnDestroy {
  private readonly ROUTES_TO_BLOCK = ['/sheet', '/kit'];
  private readonly pingInterval: number = 10000;
  private readonly pingingSubscription$ = new Subject<void>();
  private online = true;
  public onlineStatus$ = new Subject<boolean>();
  private currenlyPinging = false;

  private get isCurrentUrlToBlock(): boolean {
    return _.some(this.ROUTES_TO_BLOCK, (route) =>
      this.router.url.startsWith(route)
    );
  }

  private updateOnlineStatus(online: boolean) {
    this.online = online;
    this.onlineStatus$.next(online);
  }

  constructor(
    private readonly sharedHelper: SharedHelper,
    private readonly genericService: GenericService,
    private readonly router: Router
  ) {
    this.listenBrowserConnection();
  }

  private listenBrowserConnection(): void {
    window.addEventListener('online', () => {
      this.updateOnlineStatus(true);
      this.startPingingBackend();
    });

    window.addEventListener('offline', () => {
      this.stopPingingBackend();
      this.updateOnlineStatus(false);
      this.isCurrentUrlToBlock && this.blockOfflineWarning();
    });
  }

  public startPingingBackend(): void {
    if (this.currenlyPinging) return;
    else this.currenlyPinging = true;

    this.pingingSubscription$.next();

    timer(0, this.pingInterval)
      .pipe(takeUntil(this.pingingSubscription$))
      .subscribe(this.handleConnectionStatus.bind(this));
  }

  private async handleConnectionStatus(): Promise<void> {
    const online = await this.pingBackend();
    if (online || !this.isCurrentUrlToBlock) this.stopPingingBackend();
    if (online !== this.online) {
      this.updateOnlineStatus(online);
      if (!online && this.isCurrentUrlToBlock) this.blockOfflineWarning(true);
    }
  }

  public stopPingingBackend(): void {
    this.pingingSubscription$.next();
    this.currenlyPinging = false;
  }

  public pingBackend(): Promise<boolean> {
    return lastValueFrom(
      this.genericService.post(
        ApiPaths.EXECUTE_DEFAULT_API,
        {
          reqType: 'GET',
          url: ApiPaths.PING,
        },
        undefined,
        { showNoLoader: false, isPing: true }
      )
    )
      .then(() => true)
      .catch(() => false);
  }

  private blockOfflineWarning(backendIssue = false): void {
    this.sharedHelper.openDialog(OfflinePopupComponent, {
      minWidth: '40vw',
      maxWidth: '55vw',
      data: {
        title: '⚠ Warning! Connection Issue',
        message: backendIssue
          ? `It seems there's an issue with your internet connection or inventory server. Please ensure your internet is working properly and try again. 
          <br>If the problem persists, please contact your administrator.`
          : `You are disconnected from the internet. Please Check your internet connection.`,
      },
    });
  }

  ngOnDestroy(): void {
    this.pingingSubscription$.next();
    this.pingingSubscription$.complete();
  }
}
