import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type ParentType = {
  title: string;
  path: string;
};

export type BreadCrumbDataType = {
  parent?: ParentType[];
  title: string;
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  // I have used BehaviorSubject because I wanted to give initial value to observables
  private breadCrumb = new BehaviorSubject<BreadCrumbDataType | null>(null);
  public breadCrumb$ = this.breadCrumb.asObservable();

  private breadCrumbParent = new BehaviorSubject<BreadCrumbDataType | null>(
    null
  );
  public breadCrumbParent$ = this.breadCrumb.asObservable();

  /**
   * This method is to set page title to be set on bread crumbs
   *
   * @param breadCrumb
   */
  public setBreadCrumb(breadCrumb: BreadCrumbDataType): void {
    this.breadCrumb.next(breadCrumb);
  }
  public setBreadCrumbParent(parent: BreadCrumbDataType): void {
    this.breadCrumbParent.next(parent);
  }
  public getBreadCrumbParent(): BreadCrumbDataType | null {
    // Check if parent breadcrumb exists in BehaviorSubject
    if (this.breadCrumbParent.value) {
      return this.breadCrumbParent.value;
    }

    // Fallback to localStorage if no parent is set in BehaviorSubject
    const storedVal = localStorage.getItem('prevTitle');
    return storedVal ? JSON.parse(storedVal) : null;
  }
}
