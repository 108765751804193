import { Component } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(private readonly breadcrumbService: BreadcrumbService) {}
  ngOnInit() {
    const previousPath = this.breadcrumbService.getBreadCrumbParent();
    if (previousPath) {
      this.breadcrumbService.setBreadCrumb(previousPath);
      localStorage.setItem('prevTitle', JSON.stringify(previousPath));
    }
  }
}
